.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
  background-color: #fff;
  overflow-x: hidden;
}
.rowBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.brandingPage {
  padding: 176px 80px;
}
.brandingTitle {
  font-family: "Manrope", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}

.brandingDescription {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  color: #202427;
}
.bradningGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 56px 24px;
}
.brandingGridItem {
  align-items: center;
  justify-content: center;
  width: 410px;
  height: 232px;
  display: block;
  position: relative;
}
.brandImage {
  max-width: 410px;
  max-height: 232px;
}

.heroSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 160px 80px;
  min-height: 70vh;
  background-color: #0a1931;
}
.heroContentContainer {
  width: 50%;
}
.aboutSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 200px 80px;
  background-color: #0a1931;
}
.contactSection {
  display: flex;
  flex-direction: column;
  padding: 120px 80px;
  background-color: #fff;
}
.footerSection {
  display: flex;
  flex-direction: column;
  padding: 56px 80px 114px 80px;
  background-color: #1d1d1d;
}

.footerFont {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}

.title1 {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 56px;
  line-height: 64px;
  color: #fff;
}
.title2 {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}
.aboutTitle {
  font-family: "Manrope", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  color: #fff;
}
.aboutDesc {
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
}
.contactTitle {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #202427;
}
.contactDescription {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #202427;
}
.button {
  padding: 8px 24px;
  background: #ffffff;
  border-radius: 8px;
  font-family: "Manrope", sans-serif;
  color: #0a1931;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 40px;
  border: none;
}
.button2 {
  padding: 8px 24px;
  background: #0a1931;
  border-radius: 8px;
  font-family: "Manrope", sans-serif;
  color: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 40px;
  border: none;
}

.description {
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  font-size: 0.85rem;
  max-width: var(--max-width);
  width: 100%;
  z-index: 2;
  font-family: var(--font-mono);
}

.description a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.description p {
  position: relative;
  margin: 0;
  padding: 1rem;
  background-color: rgba(var(--callout-rgb), 0.5);
  border: 1px solid rgba(var(--callout-border-rgb), 0.3);
  border-radius: var(--border-radius);
}

.code {
  font-weight: 700;
  font-family: var(--font-mono);
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(25%, auto));
  width: var(--max-width);
  max-width: 100%;
}

.card {
  padding: 1rem 1.2rem;
  border-radius: var(--border-radius);
  background: rgba(var(--card-rgb), 0);
  border: 1px solid rgba(var(--card-border-rgb), 0);
  transition: background 200ms, border 200ms;
}

.card span {
  display: inline-block;
  transition: transform 200ms;
}

.card h2 {
  font-weight: 600;
  margin-bottom: 0.7rem;
}

.card p {
  margin: 0;
  opacity: 0.6;
  font-size: 0.9rem;
  line-height: 1.5;
  max-width: 30ch;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 4rem 0;
}

.center::before {
  background: var(--secondary-glow);
  border-radius: 50%;
  width: 480px;
  height: 360px;
}

.center::after {
  background: var(--primary-glow);
  width: 240px;
  height: 180px;
  z-index: -1;
}

.center::before,
.center::after {
  content: "";
  left: 50%;
  position: absolute;
  filter: blur(45px);
  transform: translateZ(0);
}

.logoFooter {
  height: 126px;
  width: calc(126px * 2.265);
}
/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {
  .card:hover {
    background: rgba(var(--card-rgb), 0.1);
    border: 1px solid rgba(var(--card-border-rgb), 0.15);
  }

  .card:hover span {
    transform: translateX(4px);
  }
}

@media (prefers-reduced-motion) {
  .card:hover span {
    transform: none;
  }
}

/* Tablet */
@media (max-width: 1200px) {
  .heroContentContainer {
    width: 100%;
  }
  .heroSection {
    padding: 160px 40px;
  }

  .brandingPage {
    padding: 176px 40px;
  }
  .bradningGrid {
    grid-template-columns: repeat(2, 1fr);
  }
  .brandingGridItem {
    width: 205px;
    height: 159px;
  }
  .content {
    padding: 4rem;
  }
  .aboutSection {
    padding: 200px 40px;
  }
  .contactSection {
    padding: 120px 40px;
  }

  .grid {
    grid-template-columns: 1fr;
    margin-bottom: 120px;
    max-width: unset;
    text-align: center;
  }

  .card {
    padding: 1rem 2.5rem;
  }

  .card h2 {
    margin-bottom: 0.5rem;
  }

  .center {
    padding: 8rem 0 6rem;
  }

  .center::before {
    transform: none;
    height: 300px;
  }

  .description {
    font-size: 0.8rem;
  }

  .description a {
    padding: 1rem;
  }

  .description p,
  .description div {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
  }

  .description p {
    align-items: center;
    inset: 0 0 auto;
    padding: 2rem 1rem 1.4rem;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid rgba(var(--callout-border-rgb), 0.25);
    background: linear-gradient(
      to bottom,
      rgba(var(--background-start-rgb), 1),
      rgba(var(--callout-rgb), 0.5)
    );
    background-clip: padding-box;
    backdrop-filter: blur(24px);
  }

  .description div {
    align-items: flex-end;
    pointer-events: none;
    inset: auto 0 0;
    padding: 2rem;
    height: 200px;
    background: linear-gradient(
      to bottom,
      transparent 0%,
      rgb(var(--background-end-rgb)) 40%
    );
    z-index: 1;
  }
}
@media (max-width: 744px) {
  .heroContentContainer {
    width: 100%;
  }
  .heroSection {
    padding: 160px 24px;
  }
  .title1 {
    font-family: "Manrope", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    color: #fff;
  }

  .brandingPage {
    padding: 88px 24px;
  }
  .bradningGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 56px 24px;
  }
  .brandingGridItem {
    width: 159px;
    height: 123px;
  }
  .brandImage {
    max-width: 100%;
    max-height: 100%;
  }
  .content {
    padding: 4rem;
  }
  .aboutSection {
    padding: 200px 24px;
  }
  .contactSection {
    padding: 120px 24px;
  }
  .footerSection {
    padding: 88px 24px;
  }

  .grid {
    grid-template-columns: 1fr;
    margin-bottom: 120px;
    max-width: unset;
    text-align: center;
  }
  .logoFooter {
    height: 88px;
    width: calc(88px * 2.265);
  }

  .card {
    padding: 1rem 2.5rem;
  }

  .card h2 {
    margin-bottom: 0.5rem;
  }

  .center {
    padding: 8rem 0 6rem;
  }

  .center::before {
    transform: none;
    height: 300px;
  }

  .description {
    font-size: 0.8rem;
  }

  .description a {
    padding: 1rem;
  }

  .description p,
  .description div {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
  }

  .description p {
    align-items: center;
    inset: 0 0 auto;
    padding: 2rem 1rem 1.4rem;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid rgba(var(--callout-border-rgb), 0.25);
    background: linear-gradient(
      to bottom,
      rgba(var(--background-start-rgb), 1),
      rgba(var(--callout-rgb), 0.5)
    );
    background-clip: padding-box;
    backdrop-filter: blur(24px);
  }

  .description div {
    align-items: flex-end;
    pointer-events: none;
    inset: auto 0 0;
    padding: 2rem;
    height: 200px;
    background: linear-gradient(
      to bottom,
      transparent 0%,
      rgb(var(--background-end-rgb)) 40%
    );
    z-index: 1;
  }
}
