.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 100vw;
  padding: 24px 80px;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  position: fixed;
  width: calc(100% - 160px);
}

.navbar a {
  position: relative;
  display: inline-block;
  color: #f2f2f2;
  text-align: center;
  border-bottom: 2px solid transparent;
  text-decoration: none;
  padding: 14px;
}
.navbar div a::after {
  content: "";
  width: 100%;
  height: 2px;
  background: #dedfe0;
  transform: scaleX(0);
  transition: transform 0.3s ease;
  transform-origin: center;
}

.navbar div a:hover::after {
  transform: scaleX(1);
}

.sticky {
  position: fixed;
  width: calc(100% - 160px);
}
.logo {
  height: 40px;
  width: calc(72px * 2.265);
}
.normalMenu {
  display: unset;
}
.burgerMenu {
  display: none;
}

@media (max-width: 1200px) {
  .navbar {
    padding: 24px 40px;
    width: calc(100% - 80px);
  }
  .sticky {
    position: fixed;
    width: calc(100% - 80px);
  }
  .logo {
    height: 40px;
    width: calc(48px * 2.265);
  }
  .normalMenu {
    display: unset;
  }
  .burgerMenu {
    display: none;
  }
}
@media (max-width: 744px) {
  .navbar {
    padding: 24px 24px;
    width: calc(100% - 48px);
  }
  .sticky {
    position: fixed;
    width: calc(100% - 48px);
  }
  .logo {
    height: 24px;
    width: calc(24px * 2.265);
  }
  .normalMenu {
    display: none;
  }
  .burgerMenu {
    display: unset;
  }
}
