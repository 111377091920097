.container {
  padding: 80px;
  padding-top: 160px;
  padding-bottom: 224px;
  background-color: #ffffff;
}
.infoContainer {
  display: flex;
  flex-direction: row;
}
.informationSide {
  display: flex;
  flex-direction: column;
  justify-content: "space-between";
  padding: 24px 0px 24px 24px;
}
.detailsContainer {
  padding: 24px;
  white-space: pre-line;
}
.whatsappButton {
  display: unset;
}
.imgFrame {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  border: 2px solid #f6f5f5;
}
.img {
  max-width: 424px;
  max-height: 424px;
  object-fit: fill;
}
.title {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  color: #202427;
}
.title2 {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  color: #202427;
}
.textarea {
  font-family: "Manrope", sans-serif;
  color: #202427;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.subtitle {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  color: #202427;
  white-space: pre-line;
}
.proptitle {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #737377;
}
.propinfo {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #202427;
}
.whatsappSticky {
  display: none;
}
@media (max-width: 744px) {
  .container {
    padding: 24px;
    padding-bottom: 112px;
    padding-top: 112px;
  }
  .infoContainer {
    flex-direction: column;
  }
  .imgFrame {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    background-color: white;
    border-radius: 8px;
    border: none;
  }
  .img {
    max-width: 300px;
    max-height: 424px;
    object-fit: fill;
  }
  .informationSide {
    padding: 0px;
  }
  .whatsappButton {
    display: none;
  }
  .whatsappSticky {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    z-index: 99;
    bottom: 0;
    background-color: white;
    border: 1px solid #dedfe0;
    padding-top: 32px;
    padding-bottom: 32px;
    width: 100vw;
  }
}
