/* Default styles */
.main {
  min-height: 100vh;
  max-width: 100vw;
  background-color: #fff;
}
.sideBar {
  display: none;
}

.title {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #000;
}
.catagoryButton {
  padding: 12px 24px;
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
}
.subtitle {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  color: #000;
}
.container {
  display: flex;
  padding: 80px;
  padding-top: 112px;
  gap: 24px;
}

.rowContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  max-width: 1200px;
}

.gridItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #ccc;
  height: 302px;
  aspect-ratio: 1 / 1;
}

.gridItemTitle {
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 16px;
  height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gridItem img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.gridItem h2 {
  text-align: left;
  padding: 16px;
}

/* Responsive styles */
@media only screen and (max-width: 767px) {
  /* Styles for Extra Small (XS) and Small (SM) devices */
  .container {
    padding: 24px;
    padding-top: 112px;
    display: block;
  }
  .row {
    grid-template-columns: repeat(2, 1fr);
  }
  .sideBar {
    display: flex;
    margin-bottom: 24px;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  .gridItem {
    height: 216px;
  }
  .gridItem img {
    height: 132px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* Styles for Medium (MD) devices */
  .container {
    padding: 40px;
    padding-top: 160px;
  }
  .row {
    grid-template-columns: repeat(2, 1fr);
  }
  .sideBar {
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* Styles for Large (LG) devices */
  .container {
    padding: 40px;
    padding-top: 160px;
  }
  .row {
    grid-template-columns: repeat(2, 1fr);
  }
  .catagoryButton {
    width: 188px;
  }
  .sideBar {
    display: block;
  }
}

@media only screen and (min-width: 1200px) {
  /* Styles for Extra Large (XL) devices and larger */
  .sideBar {
    display: block;
  }
  .container {
    padding: 80px;
    padding-top: 160px;
  }
  .catagoryButton {
    width: 256px;
  }
  .row {
    grid-template-columns: repeat(3, 1fr);
  }
}
