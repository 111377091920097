input::-webkit-outer-spin-button,
:-webkit-any-link {
  color: inherit; /* This will reset the color to its inherited value */
  text-decoration: none;
}
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  max-width: 1200px;
}
.gridItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #ccc;
  max-height: 302px;
  aspect-ratio: 1 / 1;
}
.gridItemTitle {
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 16px;
  height: 48px; /* Adjust the height as needed */
  overflow: hidden;
  text-overflow: ellipsis;
}

.gridItem img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.gridItem h2 {
  text-align: left;
  padding: 16px;
}

.input {
  border-radius: 8px;
  border: 1px solid #dedfe0;
  padding: 12px 16px 12px 16px;
  color: #202427;
  font-family: "Tajawal", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.18px;
  width: 100%;
  box-sizing: border-box;
}
.container {
  height: 100vh;
  padding: 80px;
}

.button {
  padding: 16px 24px;
  background: #e0e0e0;
  color: #75767e;
  border-radius: 8px;
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 40px;
  border: none;
  cursor: pointer;
}
.button:valid {
  background: #0a1931;
  color: #fff;
}
.button2 {
  padding: 16px 24px;
  background: #e0e0e0;
  border: 1px solid #75767e;
  color: #000;
  border-radius: 8px;
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 40px;
  border: none;
  cursor: pointer;
}
.button3 {
  padding: 8px 12px;
  background: #fff;
  border: 1px solid #b11616;
  color: #b11616;
  border-radius: 8px;
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 40px;

  cursor: pointer;
}
.Tx16M24 {
  font-family: "Tajawal", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #57575b;
  margin: 0px;
}
.Tx10SB16 {
  font-family: "Tajawal", sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #57575b;
}
.Tx14R24 {
  font-family: "Tajawal", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin: 0px;
  color: #b11616;
}
.Tx22M32 {
  font-family: "Tajawal", sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.01em;
  color: #57575b;
}
.error {
  color: #b11616;
  margin-top: 8px;
  margin-left: 16px;
}

@media only screen and (max-width: 767px) {
  /* Styles for Extra Small (XS) and Small (SM) devices */
  .container {
    padding: 40px;
    padding-top: 112px;
  }
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* Styles for Medium (MD) devices */
  .container {
    padding: 60px;
    padding-top: 160px;
  }
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* Styles for Large (LG) devices */
  .container {
    padding: 80px;
    padding-top: 160px;
  }
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (min-width: 1200px) {
  /* Styles for Extra Large (XL) devices and larger */
  .container {
    padding: 100px;
    padding-top: 160px;
  }
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
